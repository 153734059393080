import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { uuid } from "uuidv4";

import {
    store as generalSettingStore,
    setNumberMultiShopArchived,
    setNumberMultiShopActive,
    setIsValidateShopArchiveOpened,
    setIsLoading,
    setKey,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { languages } from "@constants/index";
import { generalConfigStore, setItemsDisable } from "@store";
import ErrorToast from "@components/Common/ErrorTost";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    isArchivedLanguagesClicked: boolean;
    setSelectedRows: Function;
};

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isArchivedLanguagesClicked,
}: CustomIconsType) {
    const { t } = useTranslation();
    const {
        numberMultiShopArchived,
        numberMultiShopActive,
        languagesData,
        modify,
        shopsLanguagesData,
        isLoading,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const [languageState, setLanguageState] = React.useState<boolean>(
        e.selectedRow.isActive
    );
    const uuidUser = localStorage.getItem("uuidUser");
    async function activeLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === e.selectedRow.abbreviation
                    )?.label,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: false,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: e.selectedRow.isActive,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: e.selectedRow.isActive,
                    abbreviation: e.selectedRow.abbreviation,
                    advancedDisplayName: e.selectedRow.advancedDisplayName,
                },
            },
        };

        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }

                        if (
                            e.selectedRow.isActive === false &&
                            itemsDisable.find(
                                (item: any) =>
                                    item.id === id &&
                                    item.shopId === e.selectedRow.shopId
                            ) === undefined
                        ) {
                            const item = [
                                ...JSON.parse(JSON.stringify(itemsDisable)),
                                {
                                    id: id,
                                    state: e.selectedRow.isActive,
                                    shopId: e.selectedRow.shopId,
                                },
                            ];
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }

                        toast.success(
                            `${t("The language was successfully unarchived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setKey(uuid());
                        setIsLoading(false);
                    })
            );
        } catch (e) {
            setIsLoading(false);
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setIsLoading(false);
    }

    async function favoriteLanguage(isFavorite: boolean) {
        if (isLoading === false) {
            setIsLoading(true);
            toast.dismiss();
            let updatedLanguage: any = {};
            let shopLanguages: any = [];
            shopsLanguagesData.forEach((element: any) => {
                if (element.shopId === e.selectedRow.shopId) {
                    shopLanguages = element.subRows;
                }
            });
            shopLanguages.forEach((language: any) => {
                let langueObject: any = JSON.parse(JSON.stringify(language));
                if (langueObject.Id === e.selectedRow.Id) {
                    Object.assign(updatedLanguage, {
                        [langueObject.Id]: {
                            id: langueObject.Id,
                            language: languages.find(
                                (lg: any) =>
                                    lg.value === langueObject.abbreviation
                            )?.label,
                            display_name: langueObject.display_name,
                            country: langueObject.country,
                            urlImage: langueObject.url_image,
                            isArchived: langueObject.isArchived,
                            isFavorite: isFavorite,
                            isActive: langueObject.isActive,
                            shopId: langueObject.shopId,
                            isActiveShop: langueObject.isActive,
                            abbreviation: langueObject.abbreviation,
                            advancedDisplayName:
                                langueObject.advancedDisplayName,
                        },
                    });
                } else {
                    Object.assign(updatedLanguage, {
                        [langueObject.Id]: {
                            id: langueObject.Id,
                            language: languages.find(
                                (lg: any) =>
                                    lg.value === langueObject.abbreviation
                            )?.label,
                            display_name: langueObject.display_name,
                            country: langueObject.country,
                            urlImage: langueObject.url_image,
                            isArchived: langueObject.isArchived,
                            isFavorite: false,
                            isActive: langueObject.isActive,
                            shopId: langueObject.shopId,
                            isActiveShop: langueObject.isActive,
                            abbreviation: langueObject.abbreviation,
                            advancedDisplayName:
                                langueObject.advancedDisplayName,
                        },
                    });
                }
            });
            let dataLanguage = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: updatedLanguage,
            };
            const updateFavorite = true;
            const data = new FormData();
            data.append(
                "dataLanguage",
                JSON.stringify({ dataLanguage, updateFavorite })
            );
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
            const requestOptions = {
                method: "POST",
                body: data,
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            setDataIsUpdated!(true);
                            toast.success(
                                isFavorite
                                    ? `${t(
                                          "Favorite state has been enabled successfully"
                                      )}`
                                    : `${t(
                                          "Favorite state has been disabled successfully"
                                      )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }
    async function enableLanguage() {
        setIsLoading(true);
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === e.selectedRow.abbreviation
                    )?.label,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: !languageState,
                    abbreviation: e.selectedRow.abbreviation,
                    advancedDisplayName: e.selectedRow.advancedDisplayName,
                },
            },
        };
        const isStateChange = true;
        const data = new FormData();
        data.append(
            "dataLanguage",
            JSON.stringify({ dataLanguage, isStateChange })
        );
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setIsLoading(false);

                        setDataIsUpdated!(true);
                        toast.success(
                            languageState
                                ? `${t(
                                      "The language was successfully deactivated"
                                  )}`
                                : `${t(
                                      "The language was successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (
                            !languageState === false &&
                            itemsDisable.find(
                                (item: any) =>
                                    item.id === id &&
                                    item.shopId === e.selectedRow.shopId
                            ) === undefined
                        ) {
                            const item = [
                                ...JSON.parse(JSON.stringify(itemsDisable)),
                                {
                                    id: id,
                                    state: !languageState,
                                    shopId: e.selectedRow.shopId,
                                },
                            ];
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        } else if (!languageState === true) {
                            const item = itemsDisable.filter(
                                (item: any) =>
                                    !(
                                        item.id === id &&
                                        item.shopId === e.selectedRow.shopId
                                    )
                            );
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }
                    })
            );
        } catch (e) {
            setIsLoading(false);
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function ArchivedLanguages() {
        let idsAssociated: any[] = [];
        if (languagesData !== undefined) {
            languagesData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedLanguages();
    async function isLanguageConsumed() {
        toast.dismiss();
        let isLanguageUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&type=languages`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) => el.id === e.selectedRow.Id
                        )
                    ) {
                        isLanguageUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isLanguageUsed;
    }
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedLanguagesClicked ||
                                    e.selectedRow.isFavorite === true
                                        ? ""
                                        : t("Enable favorite state")
                                }
                            >
                                <div>
                                    <HeartIcon
                                        onClick={() => {
                                            if (
                                                e.selectedRow.isActive ===
                                                    false &&
                                                e.selectedRow.isFavorite ===
                                                    false
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note, you cannot activate the favorite state for a disabled language"
                                                    )
                                                );
                                            } else if (
                                                e.selectedRow.isFavorite ===
                                                false
                                            ) {
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                favoriteLanguage(
                                                    !e.selectedRow.isFavorite
                                                );
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        name="heart"
                                        fill={
                                            e.selectedRow.isFavorite
                                                ? "red"
                                                : "black"
                                        }
                                        className={`${classnames(
                                            "lan_icn_BTlike",
                                            {
                                                pointer__clz:
                                                    e.selectedRow.isFavorite ===
                                                    false,
                                            },
                                            {
                                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <>
                                <Tooltip
                                    title={
                                        isArchivedLanguagesClicked
                                            ? ""
                                            : languageState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={languageState}
                                            onChange={async () => {
                                                if (
                                                    e.selectedRow.isFavorite ===
                                                    true
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Please note, you cannot deactivate a favorite language"
                                                        )
                                                    );
                                                } else {
                                                    setLanguageState(
                                                        !languageState
                                                    );
                                                    enableLanguage();
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BTenableDisable ml-3",
                                                {
                                                    "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>

                                {!isArchivedLanguagesClicked ? (
                                    <Tooltip title={t("Archive")}>
                                        <div>
                                            <TrashIcon
                                                onClick={async () => {
                                                    if (
                                                        e.selectedRow
                                                            .isFavorite === true
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot archive a favorite language"
                                                            )
                                                        );
                                                    } else {
                                                        let isConsumed = await isLanguageConsumed();
                                                        if (
                                                            isConsumed === true
                                                        ) {
                                                            ErrorToast(
                                                                t(
                                                                    "Please note, you cannot archive an element used in a template"
                                                                )
                                                            );
                                                            setIsLoading(false);
                                                        } else {
                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsValidateShopArchiveOpened(
                                                                true
                                                            );
                                                        }
                                                    }
                                                }}
                                                height={25}
                                                width={25}
                                                fill="red"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={`${classnames(
                                                    "cmn_icn_BTarchived ml-3"
                                                )}`}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={
                                            dataArchive.includes(
                                                e.selectedRow.Id
                                            )
                                                ? ""
                                                : t("Unarchive")
                                        }
                                    >
                                        <div>
                                            <EyeIcon
                                                height={25}
                                                width={25}
                                                onClick={() => {
                                                    setIsLoading(true);
                                                    activeLanguage();
                                                    setNumberMultiShopArchived(
                                                        numberMultiShopArchived -
                                                            1
                                                    );
                                                    setNumberMultiShopActive(
                                                        numberMultiShopActive +
                                                            1
                                                    );
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={`${classnames(
                                                    "pointer__clz cmn_icn_BTunarchive ml-3",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            isLoading ||
                                                            dataArchive.includes(
                                                                e.selectedRow.Id
                                                            ),
                                                    }
                                                )}`}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                style={{ cursor: "pointer" }}
                                className="pointer__clz cmn_icn_BThistory ml-3"
                                onClick={() => {
                                    setSelectedRows([e.selectedRow]);
                                    setRowData(e.selectedRow);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
