import { CheckIcon, StyledBreadcrumb } from "@aureskonnect/react-ui";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";
import { store, setGlobalDataIsUpdated, setModify, setDuration } from "./store";

import CustomIcons from "./CustomIcons";
import { ModalContentWrapper } from "./ModalContentWrapper";
import { ModalWrapper } from "./ModalWrapper";
import { useLocation } from "react-router-dom";
import { getPathAuthorization } from "@components/VerticalLayout";

export default function CCSetup() {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any>({});

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { isModalOpened, globalDataIsUpdated, key } = useSnapshot(store);
    const { shopID, franchiseID, userID, shopName, oneShop } = useSnapshot(
        useStore
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let schema: any = localStorage.getItem("schemaFranchise");

    const urlApi = `${process.env.REACT_APP_API_V2_URL}/settings/list/c&c?schema=${schema}&shopId=${shopID}&franchiseId=${franchiseID}&userId=${userID}&shopName=${shopName}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons e={e} setSelectedRows={setSelectedRows} />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 4,
            columnName: t("Application"),
            customJsx: (e: any) => <ApplicationOrder e={e} />,
        },
        {
            indexOFColumn: 5,
            columnName: t("Web site"),
            customJsx: (e: any) => <SiteWeb e={e} />,
        },
    ];
    function ApplicationOrder({ e }: any) {
        return (
            <React.Fragment>
                {e.selectedRow?.subRows === undefined &&
                e.selectedRow.application === true ? (
                    <CheckIcon
                        className="mt-1 ml-1 cmn_chk_selectButton"
                        height={23}
                        width={23}
                    />
                ) : null}
            </React.Fragment>
        );
    }

    function SiteWeb({ e }: any) {
        return (
            <React.Fragment>
                {e.selectedRow?.subRows === undefined &&
                e.selectedRow["Web site"] === 1 ? (
                    <CheckIcon
                        className="mt-1 ml-1 cmn_chk_selectButton"
                        height={23}
                        width={23}
                    />
                ) : null}
            </React.Fragment>
        );
    }

    React.useEffect(() => {
        if (i18nextLng === "fr" || i18nextLng === "en") {
            setGlobalDataIsUpdated(true);
        }
    }, [i18nextLng]);

    React.useEffect(() => {
        setDuration("");
    }, []);

    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />

            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("C&C").toUpperCase(),
                            link: "c&c",
                        },
                        {
                            item: t("C&C Setup").toUpperCase(),
                            link: "Configuration",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "To configure the C&C application and website, it is crucial to consider the sales methods and payment modes associated with the C&C site and application, as well as the VAT rates, in order to configure them appropriately."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        key={key}
                        url={urlApi}
                        showFilter
                        canSelect={true}
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        actionColumn={() => <></>}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        // customJsxSideFilterButton={
                        //     <CustomSideFilter
                        //         // setIsAddApk={setIsAddApk}
                        //         isAddApk={isAddApk}
                        //     />
                        // }
                        showGlobalFilter
                        canExpand
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        setDataIsUpdated={
                            setGlobalDataIsUpdated as
                                | React.Dispatch<
                                      React.SetStateAction<number | boolean>
                                  >
                                | undefined
                        }
                        dataIsUpdated={globalDataIsUpdated}
                        setSelectedRows={setSelectedRows}
                        name="Choice"
                    />
                    {isModalOpened ? (
                        <ModalWrapper
                            title={t("C&C Setup")}
                            selectedRows={selectedRows}
                        >
                            <ModalContentWrapper selectedRows={selectedRows} />
                        </ModalWrapper>
                    ) : null}
                    <ToastContainer limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
}
