import React from "react";
import { useTranslation } from "react-i18next";
import { GridIcon, PeripheralIcon, SettingsIcon } from "@aureskonnect/react-ui";

import { store } from "./store";
import { useSnapshot } from "valtio";

import { StyledSidebarMenu } from "./StyledSidebarMenu";

import "./layout.css";

export function menuItems(t: any) {
    return [
        {
            link: "/#",
            menuItem: t("General settings"),
            id: "5590fe0f-69fc-9ff9-699d-d9a9defd98ba",
            icon: <SettingsIcon width={25} height={25} />,
            subMenu: [
                {
                    link: "/setting/customer-account",
                    menuItem: t("Customer account informations"),
                    customClassName: "sid_btn_customerAccountInformation",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bb",
                },
                // {
                //     link: "/setting/fiscal-year",
                //     menuItem: t("Fiscal year"),
                //     customClassName: "sid_btn_fiscalYear",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bc",
                // },
                {
                    link: "/setting/vat-rate",
                    menuItem: t("VAT rate"),
                    customClassName: "sid_btn_vatRate",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bg",
                },
                {
                    link: "/setting/saleSupport",
                    menuItem: t("Sale Support"),
                    customClassName: "sid_btn_salesSupport",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bk",
                },
                {
                    link: "/setting/sale",
                    menuItem: t("Sales method"),
                    customClassName: "sid_btn_modeOfSale",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98be",
                },
                {
                    link: "/setting/information",
                    menuItem: t("Mode of information"),
                    customClassName: "sid_btn_informationModes",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bi",
                },
                {
                    link: "/setting/payment-method",
                    menuItem: t("Payment method"),
                    customClassName: "sid_btn_paymentChoice",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bd",
                },
                {
                    link: "/setting/language",
                    menuItem: t("Language"),
                    customClassName: "sid_btn_language",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bf",
                },
                {
                    link: "/setting/logo",
                    menuItem: t("Logo"),
                    customClassName: "sid_btn_logo",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bj",
                },
                {
                    link: "/setting/devise",
                    menuItem: t("Currency"),
                    customClassName: "sid_btn_currency",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bl",
                },
                // {
                //     link: "/setting/hoursShops",
                //     menuItem: t("Hours shop"),
                //     customClassName: "sid_btn_shopHours",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bm",
                // },
                // {
                //     link: "/setting/service",
                //     menuItem: t("Service"),
                //     customClassName: "sid_btn_service",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bh",
                // },
                {
                    link: "/setting/fidelity",
                    menuItem: t("Fidelity"),
                    customClassName: "sid_btn_loyalty",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bn",
                },
                {
                    link: "/setting/pos-editor",
                    menuItem: t("POS interconnection"),
                    customClassName: "sid_btn_loyalty",
                    id: "325f711b-a383-425a-9d8b-60fc261fe12c",
                },
                {
                    link: "/setting/aggregator",
                    menuItem: t("Aggregator"),
                    customClassName: "sid_btn_loyalty",
                    id: "6e63d928-d76f-4278-a85a-9fc12d594d56",
                },
                {
                    link: "/setting/thirdParty",
                    menuItem: t("Interconnection Third Party"),
                    customClassName: "sid_btn_third",
                    id: "6e63d928-d76f-4278-a85a-9fc12d594d56",
                },
            ],
            customClassName: "sid_drp_settingGeneral",
        },
        {
            icon: <GridIcon width={25} height={25} />,
            link: "/applications",
            menuItem: t("Applications"),

            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11aa",
            customClassName: "sid_drp_application",
            subMenu: [
                {
                    link: "/kiosk",
                    menuItem: t("KIOSK"),
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd11ab",
                    customClassName: "sid_drp_kiosk",
                    subMenu: [
                        {
                            link: "/project",
                            menuItem: t("Template Setup"),
                            customClassName: "sid_btn_configureTemplate",
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11ac",
                        },
                        {
                            link: "/kiosk-peripheral-setting",
                            menuItem: t("Peripheral setting"),
                            customClassName: "sid_btn_peripheralSetting",
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11ad",
                        },
                    ],
                },
                {
                    link: "/c&c",
                    menuItem: t("C&C"),
                    customClassName: "sid_btn_appClickAndCollect",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd11b1",
                    subMenu: [
                        {
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11b2",
                            link: "/Configuration",
                            menuItem: t("C&C Setup"),
                            customClassName: "sid_btn_siteClickAndCollect",
                        },
                    ],
                },
                {
                    link: "/kds",
                    menuItem: t("KDS"),
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd11c1",
                    customClassName: "sid_btn_kds",
                    subMenu: [
                        // {
                        //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd11c2",
                        //     link: "/kds-project-setting",
                        //     menuItem: t("Application Setup"),
                        //     customClassName: "sid_btn_configureTemplate",
                        // },
                        {
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11c3",
                            link: "/kds-peripheral-setting",
                            menuItem: t("Peripheral setting"),
                            customClassName: "sid_btn_siteClickAndCollect",
                        },
                    ],
                },
                // {
                //     link: "/sco",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd11d1",
                //     menuItem: t("SCO"),
                //     customClassName: "sid_btn_sco",
                //     subMenu: [
                //         {
                //             id: "5590fe0f-69fc-9ff9-699d-d9a9defd11d2",
                //             link: "/sco-project-setting",
                //             menuItem: t("Template Setup"),
                //             customClassName: "sid_btn_configureTemplate",
                //         },
                //         {
                //             id: "5590fe0f-69fc-9ff9-699d-d9a9defd11d3",
                //             link: "/sco-peripheral-setting",
                //             menuItem: t("Peripheral setting"),
                //             customClassName: "sid_btn_peripheralSetting",
                //         },
                //     ],
                // },

                // {
                //     link: "/orb",
                //     menuItem: t("ORB"),
                //     customClassName: "sid_btn_orb",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a90056a",
                //     subMenu: [
                //         {
                //             id: "75a03460-3de1-43c0-97ed-1636d0cde2c9",
                //             link: "/orb-project-setup",
                //             menuItem: t("Project setup"),
                //             customClassName: "sid_btn_configureProjectORB",
                //         },
                //         {
                //             id: "50d215f5-ac00-4f8c-897b-442b2d9968b3",
                //             link: "/orb-setup",
                //             menuItem: t("ORB Setup"),
                //             customClassName: "sid_btn_configureORB",
                //         },
                //     ],
                // },

                // {
                //     link: "/site-cc",
                //     menuItem: t("SITE C&C"),
                //     customClassName: "sid_btn_siteClickAndCollect",
                // },
                // {
                //     link: "/sco",
                //     menuItem: t("SCO"),
                //     customClassName: "sid_btn_sco",
                // },
                // {
                //     link: "/kyra",
                //     menuItem: t("KYRA"),
                //     customClassName: "sid_btn_kyra",
                // },

                // {
                //     link: "/dds",
                //     menuItem: t("DDS"),
                //     customClassName: "sid_btn_dds",
                // },
                // {
                //     link: "/rfid",
                //     menuItem: t("RFID"),
                //     customClassName: "sid_btn_rfid",
                // },
            ],
        },
        {
            link: "/#",

            id: "5590fe0f-69fc-9ff9-699d-d9a9defd12aa",
            menuItem: t("Peripherals"),
            icon: <PeripheralIcon width={25} height={25} />,
            subMenu: [
                {
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ab",
                    link: "/peripherals/printers",
                    menuItem: t("Printers"),
                    customClassName: "sid_btn_printer",
                },

                {
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ac",
                    link: "/peripherals/monetics",
                    menuItem: t("Monetics"),
                    customClassName: "sid_btn_monetics",
                },
                {
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ad",
                    link: "/peripherals/cash-management",
                    menuItem: t("Coiners"),
                    customClassName: "sid_btn_coinAcceptors",
                },
                // {
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ae",
                //     link: "/peripherals/beepers",
                //     menuItem: t("Beepers"),
                //     customClassName: "sid_btn_beeper",
                // },
            ],
            customClassName: "sid_drp_peripheral",
        },
    ];
}
export function menuItemsDefaultSetting(t: any) {
    return [
        {
            link: "/#",
            menuItem: t("General settings"),
            id: "5590fe0f-69fc-9ff9-699d-d9a9defd98ba",
            icon: <SettingsIcon width={25} height={25} />,
            subMenu: [
                {
                    link: "/setting/customer-account",
                    menuItem: t("Customer account informations"),
                    customClassName: "sid_btn_customerAccountInformation",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bb",
                },
                {
                    link: "/setting/language",
                    menuItem: t("Language"),
                    customClassName: "sid_btn_language",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bf",
                },
                // {
                //     link: "/setting/fiscal-year",
                //     menuItem: t("Fiscal year"),
                //     customClassName: "sid_btn_fiscalYear",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bc",
                // },
                {
                    link: "/setting/vat-rate",
                    menuItem: t("VAT rate"),
                    customClassName: "sid_btn_vatRate",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bg",
                },
                {
                    link: "/setting/saleSupport",
                    menuItem: t("Sale Support"),
                    customClassName: "sid_btn_salesSupport",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bk",
                },
                {
                    link: "/setting/sale",
                    menuItem: t("Sales method"),
                    customClassName: "sid_btn_modeOfSale",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98be",
                },
                {
                    link: "/setting/information",
                    menuItem: t("Mode of information"),
                    customClassName: "sid_btn_informationModes",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bi",
                },
                {
                    link: "/setting/payment-method",
                    menuItem: t("Payment method"),
                    customClassName: "sid_btn_paymentChoice",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bd",
                },

                {
                    link: "/setting/logo",
                    menuItem: t("Logo"),
                    customClassName: "sid_btn_logo",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bj",
                },
                {
                    link: "/setting/devise",
                    menuItem: t("Currency"),
                    customClassName: "sid_btn_currency",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bl",
                },
                // {
                //     link: "/setting/hoursShops",
                //     menuItem: t("Hours shop"),
                //     customClassName: "sid_btn_shopHours",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bm",
                // },
                // {
                //     link: "/setting/service",
                //     menuItem: t("Service"),
                //     customClassName: "sid_btn_service",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bh",
                // },
                {
                    link: "/setting/fidelity",
                    menuItem: t("Fidelity"),
                    customClassName: "sid_btn_loyalty",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd98bn",
                },
                {
                    link: "/setting/pos-editor",
                    menuItem: t("POS interconnection"),
                    customClassName: "sid_btn_loyalty",
                    id: "325f711b-a383-425a-9d8b-60fc261fe12c",
                },
                {
                    link: "/setting/aggregator",
                    menuItem: t("Aggregator"),
                    customClassName: "sid_btn_loyalty",
                    id: "6e63d928-d76f-4278-a85a-9fc12d594d56",
                },
                {
                    link: "/setting/thirdParty",
                    menuItem: t("Interconnection Third Party"),
                    customClassName: "sid_btn_third",
                    id: "6e63d928-d76f-4278-a85a-9fc12d594d56",
                },
            ],
            customClassName: "sid_drp_settingGeneral",
        },
        {
            icon: <GridIcon width={25} height={25} />,
            link: "/applications",
            menuItem: t("Applications"),

            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11aa",
            customClassName: "sid_drp_application",
            subMenu: [
                {
                    link: "/kiosk",
                    menuItem: t("KIOSK"),
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd11ab",
                    customClassName: "sid_drp_kiosk",
                    subMenu: [
                        {
                            link: "/project",
                            menuItem: t("Template Setup"),
                            customClassName: "sid_btn_configureTemplate",
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11ac",
                        },
                        {
                            link: "/kiosk-peripheral-setting",
                            menuItem: t("Peripheral setting"),
                            customClassName: "sid_btn_peripheralSetting",
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11ad",
                        },
                    ],
                },
                {
                    link: "/c&c",
                    menuItem: t("C&C"),
                    customClassName: "sid_btn_appClickAndCollect",
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd11b1",
                    subMenu: [
                        {
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11b2",
                            link: "#",
                            menuItem: t("C&C Setup"),
                            customClassName: "sid_btn_siteClickAndCollect",
                        },
                    ],
                },
                {
                    link: "/kds",
                    menuItem: t("KDS"),
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd11c1",
                    customClassName: "sid_btn_kds",
                    subMenu: [
                        // {
                        //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd11c2",
                        //     link: "/kds-project-setting",
                        //     menuItem: t("Application Setup"),
                        //     customClassName: "sid_btn_configureTemplate",
                        // },
                        {
                            id: "5590fe0f-69fc-9ff9-699d-d9a9defd11c3",
                            link: "/kds-peripheral-setting",
                            menuItem: t("Peripheral setting"),
                            customClassName: "sid_btn_siteClickAndCollect",
                        },
                    ],
                },
                // {
                //     link: "/sco",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd11d1",
                //     menuItem: t("SCO"),
                //     customClassName: "sid_btn_sco",
                //     subMenu: [
                //         {
                //             id: "5590fe0f-69fc-9ff9-699d-d9a9defd11d2",
                //             link: "/sco-project-setting",
                //             menuItem: t("Template Setup"),
                //             customClassName: "sid_btn_configureTemplate",
                //         },
                //         {
                //             id: "5590fe0f-69fc-9ff9-699d-d9a9defd11d3",
                //             link: "/sco-peripheral-setting",
                //             menuItem: t("Peripheral setting"),
                //             customClassName: "sid_btn_peripheralSetting",
                //         },
                //     ],
                // },

                // {
                //     link: "/orb",
                //     menuItem: t("ORB"),
                //     customClassName: "sid_btn_orb",
                //     id: "5590fe0f-69fc-9ff9-699d-d9a90056a",
                //     subMenu: [
                //         {
                //             id: "75a03460-3de1-43c0-97ed-1636d0cde2c9",
                //             link: "/orb-project-setup",
                //             menuItem: t("Project setup"),
                //             customClassName: "sid_btn_configureProjectORB",
                //         },
                //         {
                //             id: "50d215f5-ac00-4f8c-897b-442b2d9968b3",
                //             link: "/orb-setup",
                //             menuItem: t("ORB Setup"),
                //             customClassName: "sid_btn_configureORB",
                //         },
                //     ],
                // },

                // {
                //     link: "/site-cc",
                //     menuItem: t("SITE C&C"),
                //     customClassName: "sid_btn_siteClickAndCollect",
                // },
                // {
                //     link: "/sco",
                //     menuItem: t("SCO"),
                //     customClassName: "sid_btn_sco",
                // },
                // {
                //     link: "/kyra",
                //     menuItem: t("KYRA"),
                //     customClassName: "sid_btn_kyra",
                // },

                // {
                //     link: "/dds",
                //     menuItem: t("DDS"),
                //     customClassName: "sid_btn_dds",
                // },
                // {
                //     link: "/rfid",
                //     menuItem: t("RFID"),
                //     customClassName: "sid_btn_rfid",
                // },
            ],
        },
        {
            link: "/#",

            id: "5590fe0f-69fc-9ff9-699d-d9a9defd12aa",
            menuItem: t("Peripherals"),
            icon: <PeripheralIcon width={25} height={25} />,
            subMenu: [
                {
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ab",
                    link: "/peripherals/printers",
                    menuItem: t("Printers"),
                    customClassName: "sid_btn_printer",
                },

                {
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ac",
                    link: "/peripherals/monetics",
                    menuItem: t("Monetics"),
                    customClassName: "sid_btn_monetics",
                },
                {
                    id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ad",
                    link: "/peripherals/cash-management",
                    menuItem: t("Coiners"),
                    customClassName: "sid_btn_coinAcceptors",
                },
                // {
                //     id: "5590fe0f-69fc-9ff9-699d-d9a9defd12ae",
                //     link: "/peripherals/beepers",
                //     menuItem: t("Beepers"),
                //     customClassName: "sid_btn_beeper",
                // },
            ],
            customClassName: "sid_drp_peripheral",
        },
    ];
}
export default function SidebarContent() {
    const { t } = useTranslation();
    const { collapsed, isDefault } = useSnapshot(store);

    return (
        <StyledSidebarMenu
            name={collapsed ? "" : "MENU"}
            collapsed={collapsed}
            menuItems={isDefault ? menuItems(t) : menuItemsDefaultSetting(t)}
        />
    );
}
