import React from "react";
import Switch from "react-switch";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import SecondImageModel from "../../../image/SecondImageModel.png";

import {
    setSecondModelSingleLabel,
    setSecondModelOnlineOrder,
    setSecondModelMenuComponent,
    setSecondModelNameMenu,
    resetStore,
    storeModelTicket,
} from "./store";
import { setCheckboxIcon, store } from "../store";

import { PrinterFooter } from "../PrinterFooter";

import "./index.css";

type SecondModelType = {
    rowData: any;
    oneShop: boolean;
    setCheckboxIconSecondModel: Function;
};

export default function SecondModel({
    rowData,
    setCheckboxIconSecondModel,
}: SecondModelType) {
    const { t } = useTranslation();
    const {
        secondModelSingleLabel,
        secondModelOnlineOrder,
        secondModelMenuComponent,
        secondModelNameMenu,
    } = useSnapshot(storeModelTicket);
    const { checkboxIconSecondModel, checkboxIcon } = useSnapshot(store);

    return (
        <React.Fragment>
            <div className="card_modale__clz">
                <div
                    className="card card-model-second__clz"
                    style={{ fontSize: "17px" }}
                ></div>
                <div className="card-model-first__clz">
                    <div>
                        <input
                            className={classNames("check-style__clz", {
                                "not-allowed-icon__clz": checkboxIconSecondModel,
                            })}
                            style={{
                                marginBottom: "530px",
                                marginRight: "122px",
                            }}
                            type="checkbox"
                            checked={checkboxIconSecondModel}
                            onChange={() => {
                                setCheckboxIcon(!checkboxIcon);
                                setCheckboxIconSecondModel(
                                    !checkboxIconSecondModel
                                );
                            }}
                        />
                    </div>
                    <Card>
                        <img
                            src={SecondImageModel}
                            alt="Logo"
                            className="style-logo__clz"
                        />
                    </Card>
                </div>
            </div>
            <PrinterFooter rowData={rowData} resetStore={resetStore} />
        </React.Fragment>
    );
}
